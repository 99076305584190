import * as qs from "query-string";
import cookies from "js-cookie";
import $ from "jquery";
import { mangerPages } from "components/common";
import { baseUrl, showMessage } from "utils";

// const importPages = [manager];
const keys = {
  token: "shorcuts",
  role: "qingyan",
  adminToken: "adminShorcuts"
};
export function userLogined(token, saved = false) {
  sessionStorage.clear();
  sessionStorage.setItem(keys.token, token);
  cookies.set(keys.token, token);
  sessionStorage.setItem(keys.role, "1");
  saved &&
    cookies.set(keys.token, token, {
      expires: 7
    });
  // if (!rightHost.includes(window.location.hostname)) {
  //   console.log(baseDomain);
  //   const mywin = window.open(`${baseDomain}/downCallBack?token=${token}`); // 将新打的窗口对象，存储在变量mywin中
  //   setTimeout(() => {
  //     mywin.close();
  //   }, 1);
  // }
  // window.location.pathname = "/index";
}
// 获取角色页面
export function loginRolePage(token, roleId) {
  // const token = send || cookies.get("adminShorcuts");
  $.ajax({
    url: baseUrl + "/usr/menu",
    async: false,
    withCredentials: true,
    data: {
      token : token || cookies.get("adminShorcuts"),
      roleId: roleId || cookies.get("adminRoleId")
    },
    success: data => {
      for (const x of data) {
        for (const y of mangerPages) {
          if (x.name === y.name) {
            if (y.name === "工作台") {
              window.location.href = "/mymymanager/workbench/list";
            } else if (x.menus) {
              y.children.forEach((z, index) => {
                if (x.menus[index] && x.menus[index].name === z.name) {
                  window.location.href = z.path;
                  return false;
                }
              });
            } else {
              showMessage("error", "权限设置错误");
            }
            return false;
          }
        }
      }

      if (data.length === 0) {
        showMessage("error", "您没有权限访问");
      }
      data.forEach(x => {
        return mangerPages.filter(y => {
          if (x.name === y.name) {
            y.children.forEach((z, index) => {
              if (x.menus[index] && x.menus[index].name === z.name) {
                // window.location.href = z.path;
                // break;
              }
            });
            // break;
          }
        });
      });
    }
  });
}
export function adminLogined(token) {
  sessionStorage.clear();
  sessionStorage.setItem(keys.adminToken, token);
  cookies.set(keys.adminToken, token);
  sessionStorage.setItem(keys.role, "0");
  loginRolePage(token);
  // window.location.pathname = manager.startPage;
}
export function userLogout() {
  sessionStorage.clear();
  localStorage.clear();
  if (window.location.href.indexOf("/mymymanager") !== -1) {
    cookies.remove(keys.adminToken);
  } else {
    cookies.remove(keys.token);
  }
  // 清除所有cookie
  cookies.remove("adminRoleId");
}

export function getToken() {
  // 优先尝试从url获取 一次性登陆，登陆完后不保留状态
  let { token } = qs.parse(window.location.search);
  // token = cookies.get(keys.token);
  if (token) {
    // // 清除session
    // sessionStorage.clear();
    // // 清除cookie
    // cookie.remove(keys.token);
    return ["-1", token];
  }
  // 从session中获取
  const arr = [keys.role, keys.token].map(p => sessionStorage.getItem(p));
  if (arr[1]) {
    return arr;
  }
  //  管理员cookie
  token = cookies.get(keys.adminToken);
  if (token && window.location.href.indexOf("/mymymanager") !== -1) {
    return token ? ["0", token] : [];
  }
  // 从cookie中获取
  token = cookies.get(keys.token);
  return token ? ["1", token] : [];
}
