import React from "react";

// import { Loading3QuartersOutlined } from "@ant-design/icons";
// import { Spin } from "antd";

import "./index.css";

export default React.memo(function ScreenLoading() {
  return (
    <div className="screen">
      {/* <Spin
        spinning
        className="screen-spin"
        // indicator={
        //   <Loading3QuartersOutlined
        //     style={{ fontSize: 50, margin: -25 }}
        //     spin
        //   />
        // }
      >
        <div className="screen-skeleton" />
      </Spin> */}
    </div>
  );
});
