import React, { lazy } from "react";

const phoneRouter = [
  {
    name: "登录",
    path: "/phone/login",
    component: lazy(() => import("./loginBox"))
  },
  {
    name: "注册",
    path: "/phone/register",
    component: lazy(() => import("./registerBox"))
  },
  {
    name: "注册-成功页面",
    path: "/phone/registern/success",
    component: lazy(() => import("./registerBox/success"))
  },
  {
    name: "注册完进入信息填写",
    path: "/phone/regFirst",
    component: lazy(() => import("./registerBox/regFirst"))
  },
  {
    name: "忘记密码",
    path: "/phone/fogetPass",
    component: lazy(() => import("./fogetBox"))
  },
  {
    name: "个人中心",
    path: "/phone/userInfo",
    component: lazy(() => import("./userInfo"))
  },
  {
    name: "个人中心信息页",
    path: "/phone/userInfo/info",
    component: lazy(() => import("./userInfo/info"))
  },
  {
    name: "个人中心-修改昵称",
    path: "/phone/userInfo/changeName",
    component: lazy(() => import("./userInfo/info/changeName"))
  },
  {
    name: "账号与安全",
    path: "/phone/userInfo/security",
    component: lazy(() => import("./userInfo/security"))
  },
  {
    name: "绑定手机号",
    path: "/phone/userInfo/bindingPhone",
    component: lazy(() => import("./userInfo/security/bindingPhone"))
  },
  {
    name: "注销账号",
    path: "/phone/userInfo/cancellation",
    component: lazy(() => import("./userInfo/security/cancellation"))
  },
  {
    name: "注销账号-验证码确定",
    path: "/phone/userInfo/yzCode",
    component: lazy(() => import("./userInfo/security/cancellation/yzCode"))
  },
  {
    name: "邀请注册",
    path: "/phone/invitation",
    component: lazy(() => import("./invitation"))
  },
  {
    name: "邀请注册-成功页面",
    path: "/phone/invitation/success",
    component: lazy(() => import("./invitation/success"))
  },
  {
    name: "百科树",
    path: "/phone/knowlage",
    component: lazy(() => import("./encyclopedias"))
  },
  {
    name: "百科列表",
    path: "/phone/knowlage/list",
    component: lazy(() => import("./encyclopedias/list"))
  },
  {
    name: "百科列表详情",
    path: "/phone/knowlage/more",
    component: lazy(() => import("./encyclopedias/more"))
  },
  {
    name: "专家库",
    path: "/phone/experttank",
    component: lazy(() => import("./exTable"))
  },
  {
    name: "知识案例",
    path: "/phone/encyCase",
    component: lazy(() => import("./encyCase"))
  },
  {
    name: "典型案例",
    path: "/phone/case",
    component: lazy(() => import("./case"))
  },
  {
    name: "典型案例列表",
    path: "/phone/case/list",
    component: lazy(() => import("./case/list"))
  },
  {
    name: "典型案例列表",
    path: "/phone/case/list",
    component: lazy(() => import("./case/list"))
  },
  {
    name: "账户金额",
    path: "/phone/cash",
    component: lazy(() => import("./accountTrans/cash"))
  },
  {
    name: "交易记录",
    path: "/phone/transactionRecord/list",
    component: lazy(() => import("./accountTrans/list"))
  },
  {
    name: "交易记录详情",
    path: "/phone/transactionRecord/details",
    component: lazy(() => import("./accountTrans/details"))
  }
];
export default phoneRouter;
