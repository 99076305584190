import { message } from "antd";
import _ from "lodash";

export const checkPermission = (pathname, permissionRoutes = []) => {
  return !!permissionRoutes.find(route => route === pathname);
};

export const getUrlDenyListByRole = (roleMenu = [], role = 1) => {
  const urlDenyList = [];
  roleMenu.forEach(data => {
    if (data.role > role) {
      urlDenyList.push(data.value);
    } else if (data.filterGroups) {
      const parentPath = data.value;
      data.filterGroups.forEach(group => {
        group.filters.forEach(filter => {
          if (filter.role > role) {
            urlDenyList.push(`${parentPath}/${filter.value}`);
          }
        });
      });
    }
  });
  return urlDenyList;
};

export const checkResponse = res => {
  return new Promise((resolve, reject) => {
    if (res.status >= 200 && res.status < 300) {
      resolve(res.data);
    }
    reject(res.statusText);
  });
};

export const checkResponseErr = err => {
  const msg = _.get(err, "message", "系统错误");
  console.log(err);
  return Promise.reject(msg);
};

export const getNoNullObj = obj => {
  Object.keys(obj).forEach(key => {
    if (obj[key] instanceof Object) {
      getNoNullObj(obj[key]);
    }
    if (obj[key] == null || obj[key] === undefined || obj[key] === "") {
      delete obj[key];
    }
  });
  return obj;
};

export const getMergeObj = obj => {
  const newObj = {};
  Object.keys(obj).forEach(key => {
    if (key.indexOf(".") > -1) {
      _.set(newObj, key, obj[key]);
    } else {
      newObj[key] = obj[key];
    }
  });
  return newObj;
};

export const formatNumber = (number, dotnum = 2) => {
  if (!Number(number)) {
    return 0;
  }
  const newNumber = number.toFixed(dotnum);
  return Number(newNumber);
};
/**
 *
 * @param {string} type [success, error, warning]
 * @param {*} msg
 */
export const showMessage = (type, msg) => {
  message[type](msg);
};

// 获取当前月份
export const nowMonth = () => {
  const date = new Date();
  const year = date.getFullYear();
  let month = date.getMonth() + 1;
  if (month >= 1 && month <= 9) {
    month = "0" + month;
  }
  const currentdate = `${year}-${month}`;
  return currentdate;
};
// 获取月份的第一天
export const firstDate = d => {
  const year = d.split("-")[0];
  const month = d.split("-")[1];
  return `${year}-${month}-01`;
};

// 获取月份最后一天
export const lastDate = d => {
  const year = d.split("-")[0];
  const month = d.split("-")[1];
  const day = new Date(year, month, 0).getDate();
  return year + "-" + month + "-" + (day < 10 ? "0" + day : day);
};

// 获取时间戳转变为年月日
export const timestampToTime = timestamp => {
  const date = new Date(timestamp); // 时间戳为10位需*1000，时间戳为13位的话不需乘1000
  const Y = date.getFullYear() + "-";
  const M =
    (date.getMonth() + 1 < 10
      ? "0" + (date.getMonth() + 1)
      : date.getMonth() + 1) + "-";
  const D = (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + " ";
  return Y + M + D;
};
// eslint-disable-next-line import/no-mutable-exports
export const baseUrl = "/api";
export const baseDomain = "/api";
// if (process.env.REACT_APP_CURRENT === "test") {
//   baseUrl = "http://test.qyconsult.treeyee.com/";
//   baseDomain = "http://test.front.base.survey.work";
// } else if (process.env.NODE_ENV === "production") {
//   baseUrl = "https://sw.treeyee.com/api/";
//   baseDomain = "https://survey.work";
// }
//  各个项目模块对应值
export const modelArr = [
  {
    title: "检查吧",
    val: 1
  },
  {
    title: "问卷吧",
    val: 2
  },
  {
    title: "督导吧",
    val: 3
  },
  {
    title: "培训吧",
    val: 4
  }
];
