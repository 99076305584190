import React, { useState, useEffect } from "react";
import $ from "jquery";
import * as qs from "query-string";
import { Result, Spin } from "antd";
import cookies from "js-cookie";
import styles from "./Index.module.scss";

export default () => {
  const { url, token, origin } = qs.parse(window.location.search);
  let myToken = token;
  const [res, setRes] = useState(null);
  useEffect(() => {
    const dingzhiUrl =
      localStorage.getItem("dingzhiUrl") ||
      sessionStorage.getItem("dingzhiUrl") ||
      cookies.get("dingzhiUrl");
    if (origin) {
      localStorage.setItem("dingzhiUrl", origin);
      sessionStorage.setItem("dingzhiUrl", origin);
      cookies.set("dingzhiUrl", origin);
    }
    if (dingzhiUrl && window.location.origin !== dingzhiUrl && url) {
      window.location.href = `${dingzhiUrl}/downCallBack?url=${url}`;
      return;
    }
    if (!token) {
      myToken = cookies.get("shorcuts");
    }
    console.log("下載器輸出origin", origin);
    console.log("下載器輸出当前origin", window.location.origin);
    console.log("下載器輸出", dingzhiUrl, myToken);
    if (url && url.indexOf("localhost") !== -1 && myToken) {
      $.ajax({
        url,
        data: {
          token: myToken
        },
        success(data) {
          setRes(data);
          console.log({ data });
        },
        error() {
          setRes("客户端登录失败，请稍后再试");
        }
      });
    }
    if (!myToken) {
      if (!cookies.get("dingzhiUrl")) {
        setRes("请先在页面中登录");
      }
    }
  }, []);
  return (
    <div className={styles.fouBox}>
      {!res && <Spin size="large" tip="登录中" />}
      {res === "success" && <Result status="success" title="客户端登录成功" />}
      {res && res !== "success" && <Result status="error" title={res} />}
    </div>
  );
};
