import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";

export function useRedirect() {
  const [toOption, setToOption] = useState(null);
  useEffect(() => {
    return () => {
      setToOption(null);
    };
  }, [toOption]);

  return [toOption ? <Redirect to={toOption} /> : null, setToOption];
}
