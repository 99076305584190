/* eslint-disable */
import React, { useEffect, useContext, useState, useReducer } from "react";
import { withRouter } from 'react-router-dom';
import less from 'less';
import * as qs from "query-string";
import $ from 'jquery'
import ScreenLoading from "../components/screenLoading";
import { useRedirect } from "pages/hooks/redirect";
import { setRequestToken } from "../myutils/request";
import cookies from "js-cookie";
import { baseUrl } from "utils";
const defaultState = {
  companyId: null,
  // "copyRight: "@2019-2020 智慧",
  // headerLogo
  registBg: "/img/denglulogo.png",
  loginBgAppend: "/img/denglulogo_append.png",
  // header名称
  // loginTitle: "",
  // 登陆页背景图
  mainBg: "",
  // 登陆框logo
  subBg: "/img/loginLogo.png",
  // 登陆框名称
  // subTitle: "",
  // 注册页说明
  registTitle: '一个账号，可使用调研工厂旗下所有服务\n(明察暗访、网络调查、面对面访问、调研培训等)',
  loginTitle: "同一账号，可使用明察暗访、网络调查、面对面访问、调研培训等调研工厂旗下所有服务",
  // 注册页说明2
  // copyRight: '一个账号，可登录调研工厂旗下所有产品(检查吧、问卷吧、培训吧、督导吧等)',
  // favicon
  netIcon: "/img/icon.png",
  netTitle: "清研文明城市（区）创建智慧管理系统",
  color: '#BD192D',
}
const initialState = 0
const CompanySettingContext = React.createContext(initialState);
function reducer(state, action) {
  console.log('userReduce888', state, action)
  switch (action.type) {
    case 'init':
      return {
        ...state,
        ...action.payload,
      }
    default: {
      return state
    }
  }
}
export default function CompanySettingContextWrapped({ location, children }) {
  const [self, dispatch] = useReducer(reducer, initialState)
  const [redirect] = useRedirect();
  // console.log('CompanySettingContextWrapped', location)
  const [setting, setSetting] = useState(null);
  console.log("company setting", self);
  async function settingProduce() {
    const { origin, pathname } = window.location;
    console.log("测试cookieid", cookies.get("companyId"))
    const { companyid, domainId, token } = qs.parse(window.location.search)
    let comanyId = null
    let res = {
      status: 200,
      data: {}
    }
    // 有域名定制 url 加上commanyId
    
    // 獲取配置樣式 以及是否定制
    const getAllPage = (id) => {
      let finnalId = id
      if (token) {
        setRequestToken(token)
        cookies.set('shorcuts', token)
      }
      $.ajax({
        url: baseUrl + `/customer/styleCompanyId?token=${token || cookies.get('shorcuts')}`,
        async: false,
        withCredentials: true,
        success: data => {
          if(data > 0){
            finnalId = data
          }
        }
      });
      $.ajax({
        url: baseUrl + "/companySetting/all?companyId=" + finnalId,
        async: false,
        withCredentials: true,
        success: data => {
          console.log("origin、data.domain.factoryDomain", origin, data.domain.factoryDomain)
          cookies.set("jiluCompanyName",data.companyName)
          // res.data = data
          if (data.effect) {
            if (data.domain && data.domain.factoryDomain && origin !== data.domain.factoryDomain && pathname === '/login') {
              window.location.href = data.domain.factoryDomain + pathname
            }
            sessionStorage.setItem("dingzhiBol", true);
            cookies.set("dingzhiBol", true)
          } else {
            sessionStorage.removeItem("dingzhiBol");
            cookies.remove("dingzhiBol")
          }
        }
      });
      $.ajax({
        url: baseUrl + "/specialSetting/list?service=0&companyId=" + finnalId,
        async: false,
        withCredentials: true,
        success: data => {
          console.log(data)
          res.data = data
          if (data.A1) {
            res.data = {
              netTitle: data.A1.value || data.A1.default,
              netIcon: data.A2.value,
              subBg: data.A5.value,
              registBg: data.A3.value,
              mainBg: data.A83.value,
              color: data.color.value
            }
            less.modifyVars({
              '@primary-color': data.color.value,
            })
          }
        }
      });

    }
    if (!companyid && origin.indexOf("http://test.front.base.survey.work") === -1 && origin.indexOf("https://survey.work") === -1) {
      $.ajax({
        url: baseUrl + "/company/info/domain?domain=" + origin,
        async: false,
        withCredentials: true,
        success: data => {
          if (data.id) {
            comanyId = data.id
            getAllPage(data.id);
          }
        }
      });
    }
    if (domainId == null && companyid == null && comanyId == null) {
      setSetting(defaultState)
      return
    }
    if (companyid && companyid !== '0' && pathname.indexOf("encyclopedias")=== -1) {
      getAllPage(companyid)
    } else if(cookies.get("jiluCompanyid")){
      getAllPage(cookies.get("jiluCompanyid"))
    }
    if (domainId) {
      const data = await window.axios.get("/companyDomain/info?domainId=" + domainId)
      res.data = {
        mainBg: data.data.domainLogin.bgurl,
        netIcon: data.data.domainLogin.icon,
        netTitle: data.data.domainLogin.title,
        subBg: data.data.domainLogin.logo,
        registBg: data.data.domainLogin.logo,
        loginTitle: data.data.domainLogin.content,
        code: data.data.domainLogin
      }
    }

    if (res.status !== 200) {
      setSetting(defaultState)
      return
    }
    const ns = {},
      data = res.data
    Object.keys(defaultState).forEach(k => {
      const v = data[k]
      ns[k] = (v == null || v == '' ? defaultState[k] : v)
    })
    if (ns.registBg !== defaultState.registBg) {
      ns.loginBgAppend = null
    }
    if (data.code) {
      ns.code = data.code
    }
    setSetting(ns)
  }

  useEffect(() => {
    settingProduce()
  }, [self])
  useEffect(() => {
    if (setting != null) {
      console.log(setting)
      document.getElementById('custom_favicon').href = setting.netIcon
      document.querySelector('head title').innerText = setting.netTitle
    }
  }, [setting])

  return setting ?
    <CompanySettingContext.Provider value={{ setting, dispatch }}>
      {children}
      {redirect}
    </CompanySettingContext.Provider> :
    <ScreenLoading />
}

export function withCompanySetting(Component) {
  return function CompanySettingWrapped(props) {
    const s = useContext(CompanySettingContext);
    return <Component {...props} companySetting={s} />;
  };
}
/* eslint-disable */
