import React, { lazy } from "react";
import { Route, Switch, Redirect } from "react-router-dom";

import "./App.css";
import "./styles/common.scss";
import ScreenLoadingSuspense from "./pages/components/screenLoadingSuspense";
import CompanySettingContextWrapped from "./pages/context/companySetting";
import Home from "./pages/home/home";
import DownCallBack from "./pages/home/downCallBack";
import phoneRouter from "./pages/phone/router";

const Login = lazy(() => import("./pages/login"));
const Index = lazy(() => import("./pages/index"));
const PhoneWallet = lazy(() => import("./pages/user/phoneWallet/walletList"));
const PhoneDetailedList = lazy(() =>
  import("./pages/user/phoneWallet/detailedList")
);
const Authentication = lazy(() =>
  import("./pages/index/components/authentication/company")
);
const SamplePushDetails = lazy(() =>
  import("./pages/index/components/samplePush")
);
const Sample = lazy(() => import("./pages/manager/user/userList/sample"));
const PrivacyPolicy = lazy(() => import("./pages/agreeMent/privacyPolicy"));
const Visitor = lazy(() => import("./pages/user/workbench/home/visitor"));
const UserPolicy = lazy(() => import("./pages/agreeMent/userPolicy"));
const MaskSquareUserPolicy = lazy(() =>
  import("./pages/agreeMent/maskSquareUserPolicy")
);
const Update = lazy(() => import("./pages/home/update"));

function App() {
  return (
    <>
      {/* 落地页 */}
      <Route exact path="/home" component={Home} />
      <Route
        exact
        path="/"
        render={({ location }) => {
          if (location.search) {
            return <Redirect to={{ ...location, pathname: "/index" }} />;
          }
          return location.search ? (
            <Redirect to={{ ...location, pathname: "/index" }} />
          ) : (
            <Redirect to={{ ...location, pathname: "/survey" }} />
          );
        }}
      />
      <ScreenLoadingSuspense>
        <CompanySettingContextWrapped>
          <Switch>
            <Route
              exact
              path="/(login|register|mymymanager)"
              component={Login}
            />
            <Route exact path="/survey" component={Update} />
            {/* 下载器回调页面 */}
            <Route exact path="/downCallBack" component={DownCallBack} />
            {/* H5页面 */}
            <Route exact path="/user/phoneWallet" component={PhoneWallet} />
            {/* 隐私政策 */}
            <Route exact path="/privacyPolicy" component={PrivacyPolicy} />
            {/* 用户协议 */}
            <Route exact path="/userPolicy" component={UserPolicy} />
            <Route exact path="/userPolicy" component={UserPolicy} />
            {/* 认证 pageType 0 个人  1 企业  noBack 传值代表不要返回 */}
            <Route exact path="/authentication" component={Authentication} />
            {/* 访问员库 给其他吧用  pageType 代表别的吧 */}
            <Route exact path="/visitor" component={Visitor} />
            {/* 样本推送 */}
            <Route
              exact
              path="/samplePushDetails"
              component={SamplePushDetails}
            />
            {/* 样本明暗查询 */}
            <Route exact path="/sample" component={Sample} />
            {/* 调研工厂任务广场用户注册服务协议 */}
            <Route
              exact
              path="/maskSquareUserPolicy"
              component={MaskSquareUserPolicy}
            />
            <Route
              exact
              path="/user/detailedList"
              component={PhoneDetailedList}
            />
            {/* H5系列 */}
            {phoneRouter.map(x => {
              return <Route exact path={x.path} component={x.component} />;
            })}
            {/* <Route exact path="*" component={Four} /> */}
            <Route path="/:group/:member?" component={Index} />
          </Switch>
        </CompanySettingContextWrapped>
      </ScreenLoadingSuspense>
    </>
  );
}

export default App;
