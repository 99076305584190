// 导航
export const headerData = [
  {
    title: "首页",
    oHref: "#banner"
  },
  {
    title: "产品家族",
    oHref: "#product"
  },
  {
    title: "解决方案",
    oHref: "#solution"
  },
  {
    title: "产品优势",
    oHref: "#our"
  },
  {
    title: "免费使用",
    oHref: "#try"
  },
  {
    title: "联系我们",
    oHref: "#contact"
  },
  {
    title: "合作客户",
    oHref: "#customer"
  }
];
// 公司信息电话
export const info = {
  tel: "010-57135685",
  loginUrl: "/login",
  mail: "contact@survey.work",
  wx: "oliverhdh"
};
export const banner = [
  {
    title: "全方位、一站式调研平台",
    des: "提供问卷、检查、培训等一整套完备调查工具及服务",
    bgSrc: "/img/banner1.png"
  },
  {
    title: "调研数据可视化，让数据价值看得见",
    des: "调研工厂拥有强大的数据分析能力，让调研数据产生更大的价值，辅助决策",
    bgSrc: "/img/banner.png"
  },
  {
    title: "功能全免费",
    des:
      "调研工厂的所有功能，全部免费开放给专业机构和个人使用（如需定制开发、部署，请联系客服）",
    bgSrc: "/img/banner3.png"
  }
];
// 产品家族
export const productData = [
  {
    lData: {
      title: "检查吧",
      dec: "专业的精细化实地巡检测评系统",
      type: 1,
      name: "textBox left",
      dec2:
        "一站式精细化闭环实地巡检平台，强大的闭环检查和统计分析功能，助您高效完成检查、督查、核验业务。"
    },
    rData: {
      type: 2,
      src: "/img/product1.png",
      name: "imgBox right"
    },
    src: "https://pro.jianchaba.com/home"
  },
  {
    lData: {
      type: 2,
      src: "/img/product2.png",
      name: "imgBox left"
    },
    rData: {
      title: "培训吧",
      dec: "专业的企业培训平台",
      type: 1,
      name: "textBox right",
      dec2:
        "解决企业培训组织过程繁琐、培训内容形式单一、培训效果难以量化的问题；"
    },
    src: "https://pxb.treeyee.com"
  },
  {
    lData: {
      title: "问卷吧",
      dec: " 面向专业机构和个人的问卷调查平台",
      type: 1,
      name: "textBox left",
      dec2: "提高调查工作效率、提升调查成果质量"
    },
    rData: {
      type: 2,
      src: "/img/product3.png",
      name: "imgBox right"
    },
    src: "https://pro.wenjuanba.com"
  }
];
// 解决方案
export const solutionData = [
  {
    data: [
      {
        title: "全国文明城市创建系统",
        dec: "面向全国各城市提供创城和创文工作的标准化、定制化解决方案与服务。",
        title2: "解决方案",
        title3: "特色功能",
        solve: [
          "创城材料申报（包括未成年人）",
          "创城问卷调查（包括未成年人）",
          "创城实地检查"
        ],
        chara: ["创城自动考评", "创城人员培训", "创城物料设计", "创城知识库"]
      },
      {
        title: "营商环境评估考评系统",
        dec: "面向全国各地营商环境评估 的标准化或定制化解决方案",
        title2: "解决方案",
        title3: "特色功能",
        solve: ["营商环境检查", "营商环境满意度调查", "营商环境数据填报"],
        chara: ["检查数据可视化", "满意度调查数据可视化", "考评自动出分"]
      },
      {
        title: "城市管理接诉即办工作平台",
        dec:
          "面向全国各省、市、区县、街乡镇等企事业单位12345服务（即“非紧急救助服务系统”）的标准化或定制化工单接诉即办解决方案",
        title2: "解决方案",
        title3: "特色功能",
        solve: [
          "工单接入、派发",
          "工单签收、退回",
          "工单延期、亮灯警告",
          "工单整改、复核、电话回访"
        ],
        chara: ["工单数据可视化", "单位自动考核排名", "工单报告自动生成"]
      }
    ]
  },
  {
    data: [
      {
        title: "垃圾分类督查考核平台",
        dec: "面向各地区垃圾分类工作 的标准化或定制化解决方案",
        title2: "解决方案",
        title3: "特色功能",
        solve: ["垃圾分类工作考核", "垃圾分类问责体系", "垃圾分类工作督查"],
        chara: [
          "指导员签到、考勤",
          "指导员行为轨迹",
          "督查数据可视化",
          "督查报告自动生成"
        ]
      },
      {
        title: "脱贫攻坚第三方评估系统",
        dec: "面向各地区脱贫攻坚评估工作 的标准化或定制化解决方案",
        title2: "解决方案",
        title3: "特色功能",
        solve: [
          "扶贫项目盘点",
          "单位资料规范检查",
          "帮扶人责任落实检查",
          "贫困户满意度调查"
        ],
        chara: ["评估数据可视化", "评估报告自动生成", "扶贫工作培训"]
      },
      {
        title: "河道精细化管理巡检测评系统",
        dec: "面向各地区河道精细化管理巡检的标准化或定制化解决方案",
        title2: "解决方案",
        title3: "特色功能",
        solve: ["河道精细化管理实地巡察", "问题期限整改", "自动考核评分"],
        chara: [
          "巡察数据可视化",
          "检查员签到、考勤",
          "检查员行为轨迹",
          "巡察报告自动生成"
        ]
      }
    ]
  },
  {
    data: [
      {
        title: "农村人居环境评估监测系统",
        dec: "面向 各地区农村人居环境评估 的标准化或定制化解决方案",
        title2: "解决方案",
        title3: "特色功能",
        solve: [
          "农村实地巡检",
          "问卷抽样调查",
          "检查员在线培训",
          "自动考核评分"
        ],
        chara: [
          "评估数据可视化",
          "巡查典型案例库",
          "自动检查报告",
          "宣传视频展示"
        ]
      }
    ]
  }
];

export const page1 = {
  title: "产品优势",
  children: [
    {
      title: "我们更专业",
      content: "超过10年的调研经验",
      src: "/img/service4.png",
      color: "#EB2F96",
      shadowColor: "rgba(166, 55, 112, 0.08)"
    },
    {
      title: "功能更完备",
      content: "政府单位和公司的认可",
      org: "100+",
      src: "/img/service1.png",
      color: "#1890FF",
      shadowColor: "rgba(15, 93, 166, 0.08)",
      link: "https://ant.design/docs/spec/colors-cn"
    },
    {
      title: "功能全免费",
      content: "调研工厂的功能全部免费使用",
      src: "/img/service3.png",
      color: "lightgreen",
      shadowColor: "rgba(112, 73, 166, 0.08)",
      link: "https://antv.alipay.com/zh-cn/vis/index.html"
    },
    {
      title: "服务更满意",
      content: "提供完备人工培训和客服服务",
      src: "/img/service2.png",
      color: "orange",
      shadowColor: "rgba(112, 73, 166, 0.08)",
      link: "https://antv.alipay.com/zh-cn/vis/index.html"
    }
  ]
};
